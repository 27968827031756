@mixin for-mobile-only {
  @media (max-width: 767px) { @content; }
}

@mixin for-small-device-up {
  @media (min-width: 768px) { @content; }
}

@mixin for-desktop-up {
  @media (min-width: 992px) { @content; }
}

.hide-if-mobile {
  @include for-mobile-only {
    display: none !important;
  }
}

.hide-if-not-mobile {
  @include for-small-device-up {
    display: none !important;
  }
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-padding-bottom {
  padding-bottom: 0;
}

.sm-icon {
  font-size: 12px;
}

.md-icon {
  font-size: 16px;
}

.lg-icon {
  font-size: 24px;
}

.has-error {
  color: $error;
}

.filter-by-title {
  margin-bottom: 15px;
}