.responsive-table a {
  color: #1890ff !important;
}

.ant-table-pagination.ant-pagination {
  margin-right: 10px;
}

.target-save-button {
   margin-left: 10px;
}

.target-update-minus-button {
  margin-right: 5px;
}

.overwrite-target-button {
  background-color: #fff !important;
  margin-left: 5px;
}

div.overwrite th {
  background-color: #8f0808 !important;
}
